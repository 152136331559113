<template>
    <router-link :to="`/sagas/${item.id}`" class="col-12">
        <div class="card saga-card">
            <div class="card-body">
                <div class="row text-center">
                    <div class="col-2">
                        <img :src="item.image" class="preview">
                    </div>
                    <div class="col-3">
                        <h6>Released On</h6>
                        <h4>{{niceDate}}</h4>
                    </div>
                    <div class="col-4">
                        <h6>Saga</h6>
                        <h4>{{item.saga_name}}</h4>
                    </div>
                    <div class="col-3">
                        <h6>Supply</h6>
                        <h4>3000
                            <small>/{{item.total_supply}}</small>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
    export default {
        name: "SagaCard",
        props: {
            item: Object
        },
        computed: {
            niceDate: function () {
                const dt = new Date(this.item.created_at);
                return dt.toLocaleDateString()
            }
        }
    }
</script>

<style scoped>
    .preview {
        max-height: 50px;
        width: auto;
    }

    .saga-card {
        background: transparent;
        color: white;
        border: 1px solid #20126f;
    }

    .card-body::before {
        content: unset;
    }

    .card-body h4, h5 {
        color: white;
    }

    .card-body {
        padding: 1rem;
    }

    h6, small {
        color: #7a7a7a;
    }
</style>