<template>
    <div class="index">
        <BannerTop title_blue="Saga" title_white="Explorer"
                   heading="Explore all the Saga's within the AlphaBatem Universe"></BannerTop>

        <div class="container mt-3 ">
            <div class="row">
                <SagaCard class="mb-3" v-for="item in items" :key="item.id" :item="item"></SagaCard>
            </div>
        </div>

    </div>
</template>

<script>
    import Saga from "@/api/saga"
    import BannerTop from "../../components/BannerTop";
    import SagaCard from "../../components/explorer/SagaCard";

    export default {
        name: "SagaIndex",
        components: {SagaCard, BannerTop},
        data() {
            return {
                items: [],
            }
        },
        methods: {
            getIndex: function () {
				Saga.index().then((r) => this.items = r.data);
            }
        },
        mounted() {
            this.getIndex()
        }
    }
</script>

<style scoped>
</style>